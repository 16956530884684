import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { Text } from "pages/outer/components/AppText/styles";

export const Slide = styled.div`
  padding-top: 160px;
  min-height: 620px;
  position: relative;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding-top: 45px;
    min-height: 500px;
  }
`;
export const SlideContent = styled.div`
  position: relative;
  z-index: 10;

  ${() => css`
    ${Title} {
      font-size: 52px;
      margin-bottom: 25px;
      max-width: 700px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        max-width: 450px;
        font-size: 32px;
      }
    }

    ${Text} {
      font-size: 16px;
      max-width: 500px;
      color: ${(props) => props.theme.colors.gray};
      margin-bottom: 50px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        font-size: 16px;
        max-width: 360px;
      }
      @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
        color: #fff;
      }
    }
  `}
`;
export const SlideImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #000;
    opacity: 0.3;
  }
  @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
    width: 250vw;
    left: -150vw;
  }

  img {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;
